@charset "utf-8";

@import "so-simple";
       /*! div style */
       .image-gallery {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(200px, 1fr));
        justify-content: center;
        padding: 4px;
        list-style-type: none;
      }
    
      .box {
          flex-basis: 25%;
          width: 100%;
          padding: 10px;
          margin: 2px;
      }
    
      .img-gallery {
      width: 100%;
      height: 200px;
      object-fit: contain;
      transform: scale(1);
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: scale(1.05);
      }}